import type { WordpressContext } from 'contexts/wordpress-provider';
import type { Media } from 'types';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import camelcaseKeys from 'camelcase-keys';

import { Burger, Button, Logo, Wrap } from '@leafwell/components';
import dynamic from 'next/dynamic';
import { useWordPressContext } from 'contexts/wordpress-provider';
import { useCardPageUrl } from 'hooks/useCardPageUrl';
import { useHomeUrl } from 'hooks/useHomeUrl';
import { getAppUtm } from 'hooks/useCurrentUtm';
import { getStateCookie } from 'lib/cookies/state-cookie';

import MainMenu from './main-menu';
import LocaleSwitch from './locale-switch';
import SearchButton from './search-button';
import Figure from './figure';
import { useEventsTracker } from 'contexts/eventsTracker';

import { request } from 'lib/rest';

const Search = dynamic(() => import('./search'));

export type SessionButtonsType = {
  className?: string;
  translations: WordpressContext['translations'];
};

export type AdPropos = {
  adBannerDesktop: Media;
  adBannerMobile: Media;
};

const GetYourCard: React.FC<SessionButtonsType> = ({
  className,
  translations,
}) => {
  const [cardPageUrl] = useCardPageUrl();
  const stateCookie = getStateCookie();
  const { trackEvent } = useEventsTracker();

  return (
    <Button
      className={[className, 'w-full max-w-xl mx-auto'].join(' ')}
      label={translations['Get your card']}
      href={cardPageUrl}
      variant="secondary"
      rounded
      onClick={() => {
        trackEvent({
          event: 'select_content',
          content_type: translations[stateCookie],
          item_id: 'apply_medical_card',
          origin: 'main_menu',
        });
      }}
    />
  );
};

const SessionButtonsMobile: React.FC<SessionButtonsType> = ({
  className,
  translations,
}) => {
  return (
    <div className={[className, 'grid gap-y-2'].join(' ')}>
      <Button
        className={['w-full max-w-xl mx-auto'].join(' ')}
        target="_blank"
        rel="noopener"
        label={translations['Login']}
        href={process.env.NEXT_PUBLIC_LOGIN_URL}
        rounded={true}
        variant="secondary"
        filled={false}
      />
      <GetYourCard {...{ translations }} />
    </div>
  );
};

const SessionButtons: React.FC<SessionButtonsType> = ({
  className,
  translations,
}) => {
  return (
    <>
      <Button
        className={[className, 'w-full max-w-xl mx-auto'].join(' ')}
        target="_blank"
        rel="noopener"
        label={translations['Login']}
        href={process.env.NEXT_PUBLIC_LOGIN_URL}
        rounded={true}
        variant="secondary"
        filled={false}
      />
      <GetYourCard {...{ className, translations }} />
    </>
  );
};

const MAIN_MENU_ID = 'main-menu-toggle';
const HEADER_SPACINGS = 'gap-x-0 2xl:gap-y-8';

const Header: React.FC<{ pageType: string }> = ({ pageType }) => {
  const homeUrl = useHomeUrl();
  const { headerMenu, siteTitle, translations } = useWordPressContext();

  const [cardPageUrl] = useCardPageUrl();
  const stateCookie = getStateCookie();
  const [menuVisible, toggleMenu] = useState(false);
  const [searchOpen, toggleSearch] = useState(false);
  const [hideCardCta, toggleCardCta] = useState(false);
  const { trackEvent } = useEventsTracker();
  const router = useRouter();
  const { query } = router;
  const [adData, setAdData] = useState<AdPropos | null>(null);
  const isHome = homeUrl === router.pathname;
  const isCard = pageType === 'card';

  useEffect(() => {
    if (getAppUtm()) {
      toggleCardCta(true);
      window.document.body.classList.add('leafwell-mobile-app-src');
    }
  }, []);

  useEffect(() => {
    if (query.utm_campaign && !adData && isCard) {
      // call api to fetch ad data -- ad banners -- based on `utm_campaign`
      request(`/ad?slug=${query.utm_campaign}`).then(res => {
        if (res.data && res.data?.totalItems > 0) {
          const { acf } = camelcaseKeys(res.data.items[0], {
            deep: true,
          });

          const { adBannerDesktop, adBannerMobile } = acf;

          setAdData({
            adBannerDesktop,
            adBannerMobile,
          });
        }
      });
    }
  }, [query]);

  function restoreScroll() {
    window.document.body.classList.remove('overflow-hidden', 'menu-open');
  }

  function showMenu() {
    toggleMenu(true);
    window.document.body.classList.add('overflow-hidden', 'menu-open');
  }

  function hideMenu() {
    toggleMenu(false);
    restoreScroll();
  }

  return (
    <>
      <Wrap
        spacer={false}
        className={[
          'box-border',
          '!px-0 !gap-y-0',
          'top-0 xl:top-2.5 left-0 right-0 z-20 xl:z-30',
          'xl:!px-5',
          'sticky xl:fixed',
        ].join(' ')}
        size="large"
        tagName="header"
      >
        <section
          className={[
            'relative z-40 w-full px-5 grid col-start-1 col-end-5 xl:gap-y-12 mx-auto max-w-screen-2xl py-2 xl:py-5 grid-flow-col items-center justify-between grid-cols-auto-3 gap-y-6 xl:grid-cols-auto-auto',
            HEADER_SPACINGS,
            'xl:rounded-2xl border-y border-x-0 border-y-[#DEE0DE] xl:border-x xl:border-x-[#DEE0DE] xl:radius-20px',
            'bg-white',
          ].join(' ')}
        >
          <Logo
            href={homeUrl}
            variant="iconMobile"
            title={siteTitle}
            classNameButton="w-8 md:w-28 2xl:w-40 z-30"
            onClick={() =>
              trackEvent({
                event: 'select_content',
                content_type: 'Homepage',
                item_id: 'page',
                origin: 'main_menu',
              })
            }
          />
          <section
            className={[
              'grid gap-y-4 xl:grid-flow-col content-between xl:items-center xl:content-center xl:justify-start',
              'fixed top-14 md:top-16 xl:top-0 left-0 bottom-0 xl:bottom-auto w-full bg-white z-10 lg:z-20 xl:h-auto xl:bg-transparent xl:static',
              'pt-0 px-5 p-10 xl:p-0',
              HEADER_SPACINGS,
              menuVisible
                ? 'overflow-x-hidden xl:overflow-visible'
                : 'opacity-0 pointer-events-none xl:opacity-100 xl:pointer-events-auto',
            ].join(' ')}
          >
            <MainMenu
              aria-hidden={!menuVisible}
              headerMenu={headerMenu}
              menuVisible={menuVisible}
            />
            <SessionButtonsMobile
              aria-hidden={!menuVisible}
              {...{
                className: 'xl:hidden',
                translations,
              }}
            />
          </section>
          <section className="grid grid-flow-col gap-x-4 md:gap-x-2 2xl:gap-x-4 items-center pr-4 xl:pr-0">
            <SearchButton
              className="justify-self-end z-30"
              onClick={() => toggleSearch(true)}
            />
            {searchOpen && (
              <Search open={searchOpen} toggleSearch={toggleSearch} />
            )}
            <LocaleSwitch className="z-30" />
            <Button
              className={[
                'md:hidden w-full max-w-xl mx-auto',
                menuVisible ? 'hidden' : 'block',
              ].join(' ')}
              target="_blank"
              rel="noopener"
              label={translations['Login']}
              href={process.env.NEXT_PUBLIC_LOGIN_URL}
              rounded={true}
              variant="secondary"
              filled={false}
            />
            <SessionButtons
              {...{
                className: 'hidden md:inline-grid md:z-30 justify-self-end',
                translations,
              }}
            />
          </section>

          <Burger
            aria-controls={MAIN_MENU_ID}
            open={menuVisible}
            className="xl:hidden"
            layout="secondary"
            onClick={e => {
              e.preventDefault();
              menuVisible ? hideMenu() : showMenu();
            }}
          />
        </section>
        {!isCard ? (
          <section
            className={[
              'relative z-30 md:hidden grid gap-x-4 grid-cols-2 col-start-1 col-end-5 row-start-2 px-5 lg:px-10 py-3 lg:py-6 bg-lilac-50',
              (isHome || hideCardCta || menuVisible) && 'hidden',
            ].join(' ')}
          >
            <p className="z-40 self-center font-heading font-semibold text-xs sm:text-sm">
              {translations['Get your medical card online in minutes!']}
            </p>
            <Button
              className={
                'z-40 text-sm justify-self-end w-full max-w-xs mx-auto'
              }
              label={translations['Get started']}
              href={cardPageUrl}
              variant="gradient"
              size="small"
              rounded
              onClick={() => {
                trackEvent({
                  event: 'select_content',
                  content_type: translations[stateCookie],
                  item_id: 'apply_medical_card',
                  origin: 'main_menu',
                });
              }}
            />
          </section>
        ) : null}
        {isCard && adData?.adBannerDesktop ? (
          <Figure
            className="hidden md:block md:w-screen xl:absolute xl:px-5 xl:left-0 xl:top-[75px] xl:max-w-screen-2xl"
            classNameImage="xl:w-[1400px]"
            {...adData?.adBannerDesktop}
          />
        ) : null}
        {query?.utm_campaign && isCard ? (
          <span className="min-h-[120px] md:min-h-fit">
            {adData?.adBannerMobile ? (
              <Figure
                className="w-screen	md:hidden"
                {...adData?.adBannerMobile}
              />
            ) : null}
          </span>
        ) : null}
      </Wrap>
    </>
  );
};

export default Header;
